
  import { computed, defineComponent, ref } from 'vue';
  import { useI18n } from 'vue-i18n/index';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useStore } from 'vuex';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';

  export default defineComponent({
    name: 'areas-filter',
    emit: ['type-selected', 'reset'],
    components: {},
    async setup(props, { emit }) {
      const { t, te } = useI18n();
      const store = useStore();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const selectedItems = ref<any>({
        governorateId: '',
      });

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const onSubmit = () => {
        emit('type-selected', selectedItems.value);
      };

      const onReset = () => {
        selectedItems.value = {
          governorateId: '',
        };

        emit('reset', selectedItems.value);
      };
      await store.dispatch(Actions.GET_ALL_GOV_LIST);
      reinitializeComponents();
      return {
        govs: computed(() => store.getters.allListGov),
        onSubmit,
        onReset,
        translate,
        selectedItems,
      };
    },
  });
