
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
  } from 'vue';
  import { useRouter } from 'vue-router';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import AreasFilter from '@/components/dropdown/AreasFilter.vue';

  export default defineComponent({
    name: 'areas-listing',
    components: {
      Datatable,
      AreasFilter,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();
      const governorateId = ref(0);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('areasListing'), [
          translate('assets'),
        ]);
      });

      onBeforeUnmount(() => {
        store.dispatch(Actions.RESET_AREAS_STORE);
      });

      await store.dispatch(Actions.GET_AREAS);
      const loading = ref(false);
      const tableHeader = ref([
        {
          name: translate('idColumn'),
          key: 'id',
          sortable: true,
        },
        {
          name: translate('areaNameEn'),
          key: 'titleEn',
          sortable: true,
        },
        {
          name: translate('areaNameAr'),
          key: 'titleAr',
          sortable: true,
        },
        {
          name: translate('governorate'),
          key: 'governorateName',
          sortable: true,
        },
        {
          name: translate('status'),
          key: 'isActive',
          sortable: true,
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);
      const toggleStatusArea = async (area) => {
        const deletedMessage = area.isActive
          ? 'confirmInActive'
          : 'confirmActive';
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          text: translate(deletedMessage),
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          loading.value = true;
          await store.dispatch(Actions.DELETE_AREA, area.id);
          await store.dispatch(Actions.GET_AREAS);
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          } else {
            const successMsg = area.isActive
              ? 'SUCCESSFULLY_INACTIVATED'
              : 'SUCCESSFULLY_ACTIVEATED';
            Swal.fire({
              text: translate(successMsg),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: translate('ok'),
              customClass: {
                confirmButton: 'btn btn-light-primary',
              },
            });
          }
        }
      };

      const onTypeChange = async (values) => {
        loading.value = true;
        governorateId.value = values.governorateId || 0;
        // await store.dispatch(Actions.UPDATE_AD_FILTER, values.value);
        await store.dispatch(Actions.UPDATE_AREA_CURRENT_PAGE, 1);
        await store.dispatch(Actions.GET_AREAS, governorateId.value);
        loading.value = false;
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        }
      };
      const onReset = async () => {
        debugger;
        loading.value = true;
        // await store.dispatch(Actions.UPDATE_AD_FILTER, value);
        await store.dispatch(Actions.UPDATE_AREA_CURRENT_PAGE, 1);
        await store.dispatch(Actions.GET_AREAS);
        loading.value = false;
      };
      const editArea = (id) => {
        router.push({ name: 'areas-editing', params: { id } });
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_AREA_CURRENT_PAGE, val);
        await store.dispatch(Actions.GET_AREAS, governorateId.value);
        loading.value = false;
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        onTypeChange,
        onReset,
        tableData: computed(() => store.getters.areasList),
        tableHeader,
        toggleStatusArea,
        translate,
        totalItems: computed(() => store.getters.areasListCount),
        pageChanged,
        goTo,
        editArea,
        loading,
        can,
      };
    },
  });
